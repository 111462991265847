<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-5">
								<div>
									<p>Before asking for a refund, did you try to talk to customer support?</p>
									<p>Our customer support may be able to help you resolve the matter.</p>
								</div>
								<p>You can contact customer support by opening a ticket or simply email {{ $store.state.brandInfo.supportEmail }}</p>
								<p>If you want to get a refund for your subscription, below is our policy depending on your subscription</p>
								<ul class="space-y-3 ml-5 list-disc marker:text-sky-400">
									<li>If you purchased a “1 Month Subscription”, you’ll be eligible for a refund if you request it within 1 week of the time you paid.</li>
									<li>
										If you purchased a “3 Months Subscription” or longer, you’ll be eligible for a refund if you request it within 1 month of the time you paid.
									</li>
								</ul>
								<p>
									If the requirements are met, please send an email to {{ $store.state.brandInfo.billingEmail }} explaining why you want a refund and your account
									information.
								</p>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CanIGetRefund',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
